import {
  createContext,
  Dispatch,
  FC,
  RefObject,
  SetStateAction,
  useContext,
  useRef,
  useState,
} from "react";
import {
  AnalysisTableIdentifiers,
  ToolParamsGridRowDatum,
  ToolSpec,
} from "./ToolParamsGrid.types";
import {
  AnalysisTable,
  AnalysisTableGroup,
  ToolVersion,
  Tool,
} from "graphql/_Types";
import { ContextOutOfBoundsError } from "providers/ContextOutOfBoundsError";
import { GridApi } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";

export interface ToolParamsGridContextValue {
  gridApi: GridApi | undefined;
  toolId: Tool["id"];
  toolVersions: Pick<ToolVersion, "id" | "version">[];
  setGridApi: Dispatch<SetStateAction<GridApi | undefined>>;
  toolSpec: ToolSpec & { credits: number };
  gridRef: RefObject<AgGridReact<ToolParamsGridRowDatum>>;
  analysisTableGroup: Pick<AnalysisTableGroup, "id" | "name">;
  analysisTable: Pick<AnalysisTable, "id"> & {
    name: string;
    identifiers: AnalysisTableIdentifiers | null;
  };
}

/**
 * A shared context for the tool params grid
 */
const ToolParamsGridContext = createContext<
  ToolParamsGridContextValue | undefined
>(undefined);

export interface ToolParamsGridProviderProps {
  analysisTableGroup: Pick<AnalysisTableGroup, "id" | "name">;
  analysisTable: Pick<AnalysisTable, "id"> & {
    name: string;
    identifiers: AnalysisTableIdentifiers | null;
    toolVersion: Pick<ToolVersion, "id" | "version">;
  };
  toolSpec: ToolSpec & { credits: number };
  toolId: Tool["id"];
  toolVersions: Pick<ToolVersion, "id" | "version">[];
}

/**
 * A component for providing access to the tool params grid context
 */
export const ToolParamsGridProvider: FC<ToolParamsGridProviderProps> = ({
  children,
  toolSpec,
  analysisTableGroup,
  analysisTable,
  toolId,
  toolVersions,
}) => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const gridRef = useRef<AgGridReact<ToolParamsGridRowDatum>>(null);

  return (
    <ToolParamsGridContext.Provider
      value={{
        gridApi,

        setGridApi,
        toolSpec,
        gridRef,
        analysisTableGroup,
        analysisTable,
        toolId,
        toolVersions,
      }}
    >
      {children}
    </ToolParamsGridContext.Provider>
  );
};

/**
 * A hook for safely accessing the tool param grid context
 */
export const useToolParamsGridContext = () => {
  const value = useContext(ToolParamsGridContext);

  if (value === undefined) {
    throw new ContextOutOfBoundsError("ToolParamsGridContext");
  }

  return value;
};
