import { EuiText } from "@inscopix/ideas-eui";
import { CSSProperties, memo, useEffect, useMemo, useState } from "react";
import { ToolParamsGridRowDatum } from "./ToolParamsGrid.types";
import { TaskStatus } from "../../types/constants";
import { SwitchPermissioned } from "components/SwitchPermissioned/SwitchPermissioned";
import { useToolParamsGridRowDataContext } from "./ToolParamsGridRowDataProvider";
import { gql } from "@apollo/client";
import { useAttachResultsTooltipQuery } from "graphql/_Types";

gql`
  query AttachResultsTooltip($datasetIds: [UUID!]!, $recordingIds: [UUID!]!) {
    datasets: allDatasets(filter: { id: { in: $datasetIds } }) {
      nodes {
        id
        name
      }
    }

    recordings: allFileRecordingGroups(filter: { id: { in: $recordingIds } }) {
      nodes {
        id
        number
        recordingsTable: datasetRecordingsTableByDatasetRecordingsTableId {
          id
          dataset: datasetByRecordingsTableId {
            id
            prefix
          }
        }
      }
    }
  }
`;

interface AttachResultsTooltipProps {
  attachResults: boolean;
  datasetIds: string[];
  recordingIds: string[];
  taskStatus: TaskStatus | undefined;
}

/**
 * Component rendering a tooltip with the datasets and recordings where the
 * analysis results will be attached
 */
const AttachResultsTooltip = ({
  attachResults,
  datasetIds,
  recordingIds,
  taskStatus,
}: AttachResultsTooltipProps) => {
  const recordingTerm = recordingIds.length === 1 ? "recording" : "recordings";
  const datasetTerm = datasetIds.length === 1 ? "dataset" : "datasets";
  const verb = taskStatus === TaskStatus.COMPLETE ? "are" : "will";
  const adjective = taskStatus === TaskStatus.COMPLETE ? "attached" : "attach";
  const condition = attachResults ? "" : <strong>not</strong>;
  const { data, loading } = useAttachResultsTooltipQuery({
    variables: {
      datasetIds,
      recordingIds,
    },
  });

  if (loading) {
    return <span>Loading...</span>;
  }

  const datasets = data?.datasets?.nodes;
  const recordings = data?.recordings?.nodes;

  if (datasets === undefined || recordings === undefined) {
    return <span>Failed to load attachments</span>;
  }

  // TODO: nest recordings under datasets
  return (
    <EuiText grow={false}>
      <h5>
        Results {verb} {condition} {adjective} to {datasetTerm} and{" "}
        {recordingTerm} from inputs
      </h5>
      <ul>
        <li>
          Datasets
          <ul>
            {datasets.length !== 0 ? (
              datasets?.map(({ id, name }) => <li key={id}>{name}</li>)
            ) : (
              <li>None</li>
            )}
          </ul>
        </li>
        <li>
          Recordings
          <ul>
            {recordings.length !== 0 ? (
              recordings.map((recording) => (
                <li key={recording.id}>
                  {recording.recordingsTable?.dataset?.prefix}-
                  {recording.number}
                </li>
              ))
            ) : (
              <li>None</li>
            )}
          </ul>
        </li>
      </ul>
    </EuiText>
  );
};

interface GridRendererAttachResultsProps {
  data: ToolParamsGridRowDatum;
}

export const GridRendererAttachResults = memo(
  function GridRendererAttachResults({ data }: GridRendererAttachResultsProps) {
    const attachResultsFn = useToolParamsGridRowDataContext(
      (s) => s.attachResults,
    );

    const [attachResults, setAttachResults] = useState(data.attachResults);

    const style: CSSProperties = {
      alignItems: "center",
      display: "flex",
      height: "100%",
      justifyContent: "center",
      width: "100%",
    };

    // Update attachResults state when data changes (e.g. when the attachment is changed via the menubar)
    useEffect(() => {
      setAttachResults(data.attachResults);
    }, [data.attachResults]);

    const { recordings, datasets, task_status } = data;

    const handleChange = useMemo(() => {
      return (newAttachResults: boolean) => {
        setAttachResults(newAttachResults);
        void attachResultsFn([data.id], newAttachResults);
      };
    }, [attachResultsFn, data.id]);

    return (
      <div style={style}>
        <SwitchPermissioned
          aria-label="Attach Results"
          showLabel={false}
          label={"Attach Results"}
          checked={attachResults}
          compressed
          onChange={(e) => void handleChange(e.target.checked)}
          requiredPermission="edit"
          // Set a static tooltip width so it stays aligned after data is
          // fetched
          tooltipProps={{
            place: "left",
            style: { width: 250 },
          }}
          defaultTooltip={
            <AttachResultsTooltip
              attachResults={attachResults}
              datasetIds={datasets ?? []}
              recordingIds={recordings ?? []}
              taskStatus={task_status}
            />
          }
        />
      </div>
    );
  },
);
