import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { useProjectFilesStore } from "stores/project-files/ProjectFilesManager";
import { isLinkedMetadataColumn } from "../RecordingsGrid.helpers";
import { JsonValue } from "type-fest";
import gql from "graphql-tag";
import { useRecordingsGridMetadataQuery } from "graphql/_Types";
import { set } from "lodash";
import { useMemo } from "react";

gql`
  query RecordingsGridMetadata($fileIds: [UUID!]!, $metadatumKeys: [String!]!) {
    fileMetadata: allFileMetadata(
      filter: {
        and: [
          { fileId: { in: $fileIds } }
          { metadatumByMetadataId: { key: { in: $metadatumKeys } } }
        ]
      }
    ) {
      nodes {
        id
        fileId
        metadatum: metadatumByMetadataId {
          id
          key
          activeValue
        }
      }
    }
  }
`;

type UseRecordingsGridMetadataResult = {
  [fileId: string]:
    | { [metadatumKey: string]: JsonValue | undefined }
    | undefined;
};

/**
 * Hook for fetching metadata displayed in linked metadatum columns.
 * @returns A lookup map of metadatum values by file ID and metadatum key.
 */
export const useRecordingsGridMetadata = () => {
  const { recordingsTable } = useDatasetDataContext();
  const fileColumnIds = recordingsTable.datasetRecordingsTableColumns.nodes
    .filter(isLinkedMetadataColumn)
    .map((column) => column.colDef.drsFileColumnId);
  const metadatumKeys = recordingsTable.datasetRecordingsTableColumns.nodes
    .filter(isLinkedMetadataColumn)
    .map((column) => column.colDef.metadataKey);

  const sourceFileIds = useProjectFilesStore((s) => {
    return s.files
      .filter(({ columns }) =>
        columns.some(({ id }) => fileColumnIds.includes(id)),
      )
      .map((file) => file.id);
  });

  const { data } = useRecordingsGridMetadataQuery({
    variables: {
      fileIds: sourceFileIds,
      metadatumKeys,
    },
  });

  return useMemo(() => {
    const result: UseRecordingsGridMetadataResult = {};

    data?.fileMetadata?.nodes.forEach((node) => {
      const fileId = node.fileId;
      const metadatumKey = node.metadatum?.key;
      const value = node.metadatum?.activeValue;

      if (fileId !== null && metadatumKey !== undefined) {
        set(result, [fileId, metadatumKey], value);
      }
    });

    return result;
  }, [data]);
};
