import { useParams } from "react-router-dom";
import { Page404 } from "pages/404/Page404";
import {
  AnalysisTableGroup,
  Project,
  useProjectAnalysisRoutesQuery,
} from "graphql/_Types";
import { PageProjectAnalysis } from "pages/project/analysis/PageProjectAnalysis";
import { gql } from "@apollo/client";
import AppLoading from "components/AppLoading/AppLoading";
import { isDefined } from "utils/isDefined";
import { PageProjectAnalysisError } from "pages/project/analysis/PageProjectAnalysisError";

gql`
  query ProjectAnalysisRoutes($analysisTableGroupId: BigInt!) {
    analysisTableGroup: analysisTableGroupById(id: $analysisTableGroupId) {
      id
      projectId
    }
  }
`;

interface ProjectAnalysisRoutesProps {
  projectId: Project["id"];
  projectKey: Project["key"];
}

/**
 * Wrapper component that remounts {@link PageProjectAnalysis} when the
 * analysis table group ID specified by the route changes. This happens when
 * navigating to the page using the `to` prop of `EuiLink`.
 *
 * This component also checks that the analysis table group specified in the
 * route belongs to the project specified in the route.
 */
export const ProjectAnalysisRoutes = ({
  projectId,
  projectKey,
}: ProjectAnalysisRoutesProps) => {
  const { analysisTableGroupId } = useParams<{
    analysisTableGroupId: AnalysisTableGroup["id"];
  }>();
  const { data, loading, error } = useProjectAnalysisRoutesQuery({
    variables: { analysisTableGroupId },
    fetchPolicy: "cache-first",
  });
  const analysisTableGroup = data?.analysisTableGroup;

  if (loading) {
    return <AppLoading />;
  }

  if (isDefined(error)) {
    return <PageProjectAnalysisError />;
  }

  if (analysisTableGroup?.projectId !== projectId) {
    return <Page404 />;
  }

  return (
    <PageProjectAnalysis
      // Key prop necessary to force component remount when route param changes
      key={analysisTableGroupId}
      analysisTableGroupId={analysisTableGroupId}
      projectId={projectId}
      projectKey={projectKey}
    />
  );
};
