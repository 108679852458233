import { EuiIcon } from "@inscopix/ideas-eui";
import { ColDef, ICellRendererParams, IHeaderParams } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import assert from "assert";
import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { useDatasetLayoutContext } from "pages/project/dataset/DatasetLayoutProvider";
import { memo, useCallback, useEffect, useMemo } from "react";
import { isDefined } from "utils/isDefined";
import { GridRendererDrsFile } from "./GridRendererDrsFile/GridRendererDrsFile";
import { GridRendererRecording } from "./GridRendererRecording";
import { RecordingsGridColumnHeader } from "components/RecordingsGrid/headers/RecordingsGridColumnHeader";
import {
  RECORDINGS_GRID_DEFAULT_COLUMN_WIDTH,
  RecordingsGridRowDatum,
} from "./RecordingsGrid.types";
import { AnalysisTable } from "graphql/_Types";
import {
  AnalysisResultColumn,
  DrsFileColumn,
  isAnalysisResultColumn,
  isDrsFileColumn,
  isLinkedMetadataColumn,
  isMetadataColumn,
  LinkedMetadataColumn,
} from "./RecordingsGrid.helpers";
import { GridRendererMetadata } from "./GridRendererMetadata";
import { useProjectDataContext } from "pages/project/ProjectDataProvider";
import { ColGroupDef } from "ag-grid-community/dist/lib/entities/colDef";
import { GridRendererAnalysisResult } from "./GridRendererAnalysisResult";
import { useDatasetAction } from "hooks/useDatasetAction/useDatasetAction";
import { useRecordingsGridsGetContextMenuItems } from "components/RecordingsGrid/hooks/useRecordingsGridsGetContextMenuItems";
import { LinkedMetadataColumnIcon } from "./LinkedMetadataColumnIcon";
import { GridRendererRowNumber } from "./GridRendererRowNumber";
import {
  ColumnResizedEvent,
  DragStoppedEvent,
  GridOptions,
  IHeaderGroupParams,
  SelectionChangedEvent,
} from "ag-grid-community";
import { AnalysisResultColumnGroupHeader } from "./headers/AnalysisResultColumnGroupHeader";
import { useProjectFilesStore } from "stores/project-files/ProjectFilesManager";
import { IValueGetterParams } from "types/AgGrid.types";
import { useParseMetadataColumn } from "./hooks/useParseMetadataColumn";
import { RecordingSessionColumnHeader } from "./headers/RecordingSessionColumnHeader/RecordingSessionColumnHeader";
import { FILE_TYPES_BY_ID, FILE_TYPES_BY_KEY } from "types/FileTypes";
import { captureException } from "@sentry/react";
import { isNonNull } from "utils/isNonNull";
import { useDndMonitor } from "@dnd-kit/core";
import { DroppableData } from "components/Dataset/DatasetDndProvider";
import { useDatasetSelectionContext } from "pages/project/dataset/SelectionProvider";
import { useRecordingsGridMetadata } from "./hooks/useRecordingsGridMetadata";
import { JsonValue } from "type-fest";

export const RecordingsGrid = memo(function RecordingsGrid() {
  const setSelectedRows = useDatasetSelectionContext((s) => s.setSelectedRows);

  const { analysisTableGroups, analysisResultsByTableId } =
    useProjectDataContext();
  const {
    recordingsTable,
    columnsById,
    dataset,
    datasetExportName,
    project,
    datasetMode,
  } = useDatasetDataContext();
  const { gridRef } = useDatasetLayoutContext();
  const files = useProjectFilesStore((s) => s.files);
  const reorderColumnsAction = useDatasetAction("reorderColumns");
  const resizeColumnAction = useDatasetAction("resizeColumn");
  const { parseMetadataColumn } = useParseMetadataColumn();
  const fileMetadata = useRecordingsGridMetadata();

  const colDefRecording: ColDef<RecordingsGridRowDatum> = useMemo(
    () => ({
      headerName: "Recording Session ID",
      cellRenderer: GridRendererRecording,
      colId: "recording",
      headerCheckboxSelection: () => datasetMode.type === "current",
      checkboxSelection: () => datasetMode.type === "current",
      showDisabledCheckboxes: true,
      headerComponent: (params: IHeaderParams) => (
        <RecordingSessionColumnHeader
          {...params}
          columns={Object.values(columnsById)}
          dataset={dataset}
          projectId={project.id}
          recordingsTableId={recordingsTable.id}
        />
      ),
      pinned: "left",
      lockPosition: true,
      valueGetter: ({ data }) => {
        assert(
          isDefined(data),
          "valueGetter should only be called when data is defined for recording column",
        );
        return `${dataset.prefix}-${data.recording.number}`;
      },
    }),
    [columnsById, dataset, datasetMode.type, project.id, recordingsTable.id],
  );

  const colDefRowNumber: ColDef = useMemo(() => {
    return {
      cellRenderer: GridRendererRowNumber,
      cellStyle: {
        padding: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      colId: "rowNumber",
      headerName: "",
      pinned: "left",
      resizable: false,
      width: 50,
      lockPosition: true,
    };
  }, []);

  const staticColDefs = useMemo(
    () => [colDefRowNumber, colDefRecording],
    [colDefRowNumber, colDefRecording],
  );

  const parseDrsFileColumn = useCallback(
    (
      column: DrsFileColumn<{
        pinned: boolean;
        width: number;
      }>,
    ) => {
      const { id } = column;
      const { headerName, fileType } = column.colDef;

      const colDef: ColDef<RecordingsGridRowDatum> = {
        cellRenderer: ({
          data,
        }: ICellRendererParams<RecordingsGridRowDatum>) => {
          assert(
            data !== undefined,
            "Expected row datum to be defined in drsFile cell renderer",
          );

          const drsFiles = files.filter((file) => {
            return (
              file.columns?.some(({ id }) => id === column.id) &&
              file.recordings?.some(({ id }) => id === data.recording.id)
            );
          });

          return (
            <GridRendererDrsFile
              column={column}
              datasetId={data.dataset.id}
              drsFiles={drsFiles}
              recordingId={data.recording.id}
            />
          );
        },
        cellStyle: { padding: 0 },
        colId: id,
        headerComponent: (params: IHeaderParams) => (
          <RecordingsGridColumnHeader
            {...params}
            column={column}
            icon={<EuiIcon size="s" type={FILE_TYPES_BY_ID[fileType].icon} />}
          />
        ),
        pinned: column.pinned,
        width: column.width,
        headerName,
        valueGetter: ({ data }) => {
          assert(
            isDefined(data),
            "Expected row datum to be defined in drsFile value getter",
          );

          const drsFiles = files.filter((file) => {
            return (
              file.columns?.some(({ id }) => id === column.id) &&
              file.recordings?.some(({ id }) => id === data.recording.id)
            );
          });

          return drsFiles;
        },
      };

      return colDef;
    },
    [files],
  );

  const parseLinkedMetadataColumn = useCallback(
    (
      column: LinkedMetadataColumn<{
        pinned: boolean;
        width: number;
      }>,
    ) => {
      const { id } = column;
      const { headerName, metadataKey, drsFileColumnId } = column.colDef;

      const colDef: ColDef<RecordingsGridRowDatum> = {
        cellRenderer: GridRendererMetadata,
        cellRendererParams: {
          metadataKey,
          metadataName: headerName,
          drsFileColumnId,
        },
        colId: id,
        headerName,
        headerComponent: (params: IHeaderParams) => {
          const dataColumn = (() => {
            const _dataColumn = columnsById[column.colDef.drsFileColumnId];
            assert(
              isDrsFileColumn(_dataColumn) ||
                isAnalysisResultColumn(_dataColumn),
              "Expected link to data column",
            );
            return _dataColumn;
          })();
          return (
            <RecordingsGridColumnHeader
              {...params}
              column={column}
              icon={
                <LinkedMetadataColumnIcon
                  column={column}
                  linkedCol={dataColumn}
                  analysisResultsByTableId={analysisResultsByTableId}
                />
              }
            />
          );
        },
        pinned: column.pinned,
        width: column.width,
        valueGetter: ({ data }) => {
          assert(
            isDefined(data),
            "Expected row datum to be defined in linked metadata cell value getter",
          );
          const filteredFiles = files.filter((file) => {
            return (
              file.columns?.some(
                ({ id }) => id === column.colDef.drsFileColumnId,
              ) && file.recordings?.some(({ id }) => id === data.recording.id)
            );
          });

          const metadatumValues: JsonValue[] = [];
          // get metadata values from each matching object
          for (const file of filteredFiles) {
            const value = fileMetadata[file.id]?.[metadataKey];
            if (value !== undefined) {
              metadatumValues.push(value);
            }
          }
          // if there is only one matching metadatum value, turn it
          if (metadatumValues.length === 1) {
            return metadatumValues[0];
          }
          // if multiple match, check if all defined values are the same
          else {
            let comparisonValue = null;
            for (const metadataValue of metadatumValues) {
              if (
                metadataValue !== undefined &&
                metadataValue !== null &&
                metadataValue !== ""
              ) {
                if (comparisonValue == null) {
                  comparisonValue = metadataValue;
                }
                // if they are not the same, render an asterisk to represent mismatched values
                if (metadataValue !== comparisonValue) {
                  return "*";
                }
              }
            }
            // otherwise return the matching value (or null if nothing was populated)
            return comparisonValue;
          }
        },
      };

      return colDef;
    },
    [analysisResultsByTableId, columnsById, files, fileMetadata],
  );

  const parseAnalysisResultColumnGroup = useCallback(
    (
      analysisTableId: AnalysisTable["id"],
      columns: AnalysisResultColumn<{
        pinned: boolean;
        width: number;
      }>[],
    ) => {
      const analysisTables = analysisTableGroups.flatMap((group) =>
        group.analysisTables.map((table) => ({ group, ...table })),
      );
      const analysisTable = analysisTables.find(
        ({ id }) => id === analysisTableId,
      );

      assert(
        analysisTable !== undefined,
        "Expected analysis table to be defined.",
      );

      const toolSpec = analysisTable.toolSpec;

      return {
        groupId: analysisTable.id,
        headerName: `${analysisTable.group.name} (${analysisTable.name})`,
        headerGroupComponent: (params: IHeaderGroupParams) => (
          <AnalysisResultColumnGroupHeader
            {...params}
            analysisTableGroup={analysisTable.group}
            analysisTable={analysisTable}
            project={project}
          />
        ),
        children: columns
          .map((column) => {
            const { resultKey } = column.colDef;
            const analysisResult = toolSpec.results[0].files.find(
              (datum) => datum.result_key === resultKey,
            );
            if (analysisResult === undefined) {
              captureException(
                "Failed to find the analysis result associated with an analysis result column",
                {
                  extra: {
                    resultKey: resultKey,
                    tool: toolSpec.name,
                    version: toolSpec.version,
                  },
                },
              );
              return null;
            }

            return {
              cellRenderer: ({
                data,
              }: ICellRendererParams<RecordingsGridRowDatum>) => {
                assert(
                  data !== undefined,
                  "Expected row datum to be defined in analysis results cell renderer",
                );

                const drsFiles = files.filter((file) => {
                  return (
                    file.columns?.some(({ id }) => id === column.id) &&
                    file.recordings?.some(({ id }) => id === data.recording.id)
                  );
                });

                return <GridRendererAnalysisResult drsFiles={drsFiles} />;
              },
              valueGetter: ({
                data,
              }: IValueGetterParams<RecordingsGridRowDatum>) => {
                assert(
                  data !== undefined,
                  "Expected row datum to be defined in analysis results cell value getter",
                );

                return files.filter((file) => {
                  return (
                    file.columns?.some(({ id }) => id === column.id) &&
                    file.recordings?.some(({ id }) => id === data.recording.id)
                  );
                });
              },

              cellStyle: { padding: 0 },
              colId: column.id,
              headerName: analysisResult.result_name,
              pinned: column.pinned,
              width: column.width,
              headerComponent: (params: IHeaderParams) => (
                <RecordingsGridColumnHeader
                  {...params}
                  column={column}
                  icon={
                    <EuiIcon
                      size="s"
                      type={
                        FILE_TYPES_BY_KEY[analysisResult.file_type]?.icon ??
                        FILE_TYPES_BY_KEY["unknown"].icon
                      }
                    />
                  }
                />
              ),
            };
          })
          .filter(isNonNull),
      } satisfies ColGroupDef;
    },
    [analysisTableGroups, files, project],
  );

  const customColDefs = useMemo(() => {
    const colDefs: (ColDef | ColGroupDef)[] = [];

    const analysisResultColumnsByAnalysisTableId: Map<
      AnalysisTable["id"],
      AnalysisResultColumn<{
        pinned: boolean;
        width: number;
      }>[]
    > = new Map();

    const columns = recordingsTable.datasetRecordingsTableColumns.nodes;

    columns.forEach((column) => {
      if (isAnalysisResultColumn(column)) {
        const { analysisTableId } = column.colDef;

        // Initialize the accumulator for the analysis table
        if (!analysisResultColumnsByAnalysisTableId.has(analysisTableId)) {
          analysisResultColumnsByAnalysisTableId.set(analysisTableId, []);
        }

        // Add the column to the accumulator
        analysisResultColumnsByAnalysisTableId
          .get(analysisTableId)
          ?.push(column);
      } else if (isDrsFileColumn(column)) {
        const colDef = parseDrsFileColumn(column);
        colDefs.push(colDef);
      } else if (isMetadataColumn(column)) {
        const colDef = parseMetadataColumn(column);
        colDefs.push(colDef);
      } else if (isLinkedMetadataColumn(column)) {
        const colDef = parseLinkedMetadataColumn(column);
        colDefs.push(colDef);
      } else {
        const columnType = column.colDef.type;
        throw new Error(`Failed to parse column of type "${columnType}"`);
      }
    });

    analysisResultColumnsByAnalysisTableId.forEach(
      (columns, analysisTableId) => {
        const colGroupDef = parseAnalysisResultColumnGroup(
          analysisTableId,
          columns,
        );
        colDefs.push(colGroupDef);
      },
    );

    return colDefs;
  }, [
    recordingsTable.datasetRecordingsTableColumns.nodes,
    parseDrsFileColumn,
    parseMetadataColumn,
    parseLinkedMetadataColumn,
    parseAnalysisResultColumnGroup,
  ]);

  const rowData: RecordingsGridRowDatum[] = useMemo(() => {
    return recordingsTable.recordingGroups.nodes.map((recording) => {
      return {
        id: recording.id,
        dataset,
        recording,
      };
    });
  }, [dataset, recordingsTable]);

  const handleDragStopped = useCallback(
    (e: DragStoppedEvent<RecordingsGridRowDatum>) => {
      const columnState = e.columnApi.getColumnState();
      const customColumns = columnState
        // Remove static columns (e.g. row number, select row, etc)
        .filter(({ colId }) => columnsById[colId] !== undefined);

      const isColumnOrderChanged = customColumns.some(({ colId }, idx) => {
        const oldOrder = columnsById[colId].order;
        const newOrder = idx;
        return newOrder !== oldOrder;
      });

      if (isColumnOrderChanged) {
        void reorderColumnsAction.enqueue({
          recordingsTableId: recordingsTable.id,
          orderedColumnIds: customColumns.map(({ colId }) => colId),
        });
      }
    },
    [columnsById, recordingsTable.id, reorderColumnsAction],
  );

  const handleColumnResized = useCallback(
    (e: ColumnResizedEvent) => {
      const isColumnStillDragging = !e.finished;
      if (isColumnStillDragging || e.source !== "uiColumnResized") {
        return;
      }
      const columnState = e.columnApi.getColumnState();
      const customColumns = columnState
        // Remove static columns (e.g. row number, select row, etc)
        .filter(({ colId }) => columnsById[colId] !== undefined);

      // Only one column can be resized at a time
      const resizedColumn = customColumns.find((column) => {
        const oldWidth = columnsById[column.colId].width;
        const newWidth = column.width;
        return newWidth !== oldWidth;
      });

      if (resizedColumn !== undefined) {
        const newWidth =
          resizedColumn.width ?? RECORDINGS_GRID_DEFAULT_COLUMN_WIDTH;

        void resizeColumnAction.enqueue({
          column: {
            id: resizedColumn.colId,
            width: newWidth,
          },
        });
      }
    },
    [columnsById, resizeColumnAction],
  );

  const getContextMenuItems = useRecordingsGridsGetContextMenuItems(
    gridRef,
    datasetExportName,
  );

  /**
   * Sets the column order through the grid's column API. This is necessary
   * because the grid cannot split columns within a column group automatically.
   */
  const setColumnOrder = useCallback(() => {
    const columnApi = gridRef.current?.columnApi;
    if (columnApi !== undefined) {
      const columns = recordingsTable.datasetRecordingsTableColumns.nodes;
      columns.forEach((column, idx) => {
        columnApi.moveColumn(column.id, idx + staticColDefs.length);
      });
    }
  }, [
    gridRef,
    recordingsTable.datasetRecordingsTableColumns.nodes,
    staticColDefs.length,
  ]);

  /**
   * Sync the column order anytime the column definitions change
   */
  useEffect(() => {
    setColumnOrder();
  }, [setColumnOrder]);

  //
  // Sync the range selection with the dragging files inside the grid
  //
  useDndMonitor({
    // Prevents the ag-grid from selecting cells (range selection) when dragging a FileBadge
    onDragMove() {
      gridRef.current?.api.clearRangeSelection();
    },
    // Resets the range selection when the drag ends
    onDragEnd(event) {
      const current = event.over?.data?.current as DroppableData | undefined;
      if (!current) return;

      const rowNode = gridRef.current?.api.getRowNode(current.recordingId);
      if (!rowNode) return;

      gridRef.current?.api?.addCellRange({
        rowStartIndex: rowNode.rowIndex,
        rowEndIndex: rowNode.rowIndex,
        columns: [current.column.id],
      });
    },
  });

  const gridOptions: GridOptions = useMemo(
    () => ({
      rowSelection: "multiple",
      suppressRowClickSelection: true,
    }),
    [],
  );

  const handleSelectionChanged = useCallback(
    (e: SelectionChangedEvent<RecordingsGridRowDatum>) => {
      setSelectedRows(e.api.getSelectedRows());
    },
    [setSelectedRows],
  );

  return (
    <AgGridReact<RecordingsGridRowDatum>
      className="ag-theme-balham-cell-borders"
      columnDefs={[...staticColDefs, ...customColDefs]}
      onColumnResized={(e) => void handleColumnResized(e)}
      defaultColDef={{
        editable: false,
        lockPinned: true,
        lockVisible: true,
        menuTabs: ["generalMenuTab"],
        resizable: !resizeColumnAction.isDisabled,
        suppressMenu: true,
        suppressMovable: reorderColumnsAction.isDisabled,
        // setting to false to avoid unexpected changes to existing grids when bumping from 28.x to 30.x
        // if using this grid as a template for new grids, consider taking advantage of this new feature
        // https://www.ag-grid.com/archive/30.0.0/react-data-grid/cell-data-types/
        cellDataType: false,
        /* By default, AG Grid will use the delete key to "clear" a cell. Under
           the hood, this sets a cell value to `null` without going through
           cell editors first. This causes issues because some backend models
           (like `MetadataValue`) cannot have null values. */
        suppressKeyboardEvent: ({ event }) =>
          event.key === "Delete" || event.key === "Backspace",
      }}
      enableRangeSelection={true}
      getContextMenuItems={getContextMenuItems}
      getRowId={({ data }) => data.recording.id}
      ref={gridRef}
      rowData={rowData}
      rowHeight={35}
      suppressDragLeaveHidesColumns
      suppressRowHoverHighlight
      // Preserves the column order even if the `columnDefs` prop is updated
      maintainColumnOrder
      onDragStopped={handleDragStopped}
      onGridReady={setColumnOrder}
      gridOptions={gridOptions}
      onSelectionChanged={handleSelectionChanged}
    />
  );
});
